import { useEffect, useRef, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faXmark } from "@fortawesome/free-solid-svg-icons"
import Loading from "./Loading"
import { fri9g_notify } from "fri9g-notification-react"
import { useNavigate } from "react-router-dom"

const Preferences = (props) => {
  const {t,i18n,prefActiveRef,setPrefActiveRef,initialDataRef,currencyRef,setCurrencyRef} = props
  const navigate = useNavigate()

  const [loading,setLoading] = useState(true)
  const loadingRef = useRef(loading)
  const setLoadingRef = (data) => {
    loadingRef.current = data
    setLoading(data)
  }
  
  const [currencySelect,setCurrencySelect] = useState(currencyRef.current)
  const currencySelectRef = useRef(currencySelect)
  const setCurrencySelectRef = (data) => {
    currencySelectRef.current = data
    setCurrencySelect(data)
  }

  const [localeSelect,setLocaleSelect] = useState(i18n.language)
  const localeSelectRef = useRef(localeSelect)
  const setLocaleSelectRef = (data) => {
    localeSelectRef.current = data
    setLocaleSelect(data)
  }

  const save = (ev) => {
    ev.preventDefault()
    setCurrencyRef(currencySelectRef.current)
    i18n.changeLanguage(localeSelectRef.current)
    setPrefActiveRef(false)
    fri9g_notify.success(t("preferences.saved"))
    navigate('/')
  }

  useEffect(()=>{
    setLocaleSelectRef(i18n.language)
    setCurrencySelectRef(currencyRef.current)
  },[currencyRef,i18n.language])

  return <div className={`preferences cart ${prefActiveRef.current ? 'active' : ''}`}>
    <div className="filter"></div>
    <div className="container">
      <div className="cartHeader">
        <h1>{t("preferences.title")}</h1>
        <FontAwesomeIcon icon={faXmark} onClick={()=>{setPrefActiveRef(false)}}/>
      </div>
      <div className="cartBody">
        <div className="languages">
          {initialDataRef.current.locales.map((e,k)=> <div className={`localeUnit ${localeSelectRef.current === e.iso ? 'active' : ''}`} onClick={()=>{setLocaleSelectRef(e.iso)}} key={`locales-${e.iso}-${k}`}>
            <img src={`/images/flag/locales/${e.iso}.png`} alt={`${e.iso} flag`}/>
            <h4>{(e.iso).toUpperCase()}</h4>
          </div>)}
        </div>
        <div className="divide"></div>
        <div className="currencies">
          {initialDataRef.current.currencies.map((e,k) => <div className={`currencyUnit ${currencySelectRef.current.code === e.code ? 'active' : ''}`} onClick={()=>{setCurrencySelectRef(e)}} key={`currency-${e.code}-${k}`}>
            <h4>{e.symbol}</h4>
            <h4>{e.code}</h4>
          </div>)}
        </div>
      </div>
      <div className="cartFooter">
        <button onClick={ev => {save(ev)}}>{t("preferences.save")}</button>
      </div>
    </div>
  </div>
}
export default Preferences