import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCartShopping, faGlobe, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons"
import { faUser } from "@fortawesome/free-regular-svg-icons"
import { useRef, useState } from "react"
import authApi from "../services/authAPI"

const Nav = (props) => {
  const {t,i18n,cart,categoriesRef,setCartActiveRef,setPrefActiveRef} = props

  // const [search,setSearch] = useState("")
  // const searchRef = useRef(search)
  // const setSearchRef = (data) => {
  //   searchRef.current = data
  //   setSearch(data)
  // }

  const [localeActive,setLocaleActive] = useState(false)
  const localeActiveRef = useRef(localeActive)
  const setLocaleActiveRef = (data) => {
    localeActiveRef.current = data
    setLocaleActive(data)
  }

  const [userActive,setUserActive] = useState(false)
  const userActiveRef = useRef(userActive)
  const setUserActiveRef = (data) => {
    userActiveRef.current = data
    setUserActive(data)
  }
  

  // const searchChange = async (e) => {
  //   e.preventDefault()
  //   setSearchRef(e.target.value)
  //   //TO DO SEARCH CALL
  // }

  return (<div className="header">
    <div className="wrapper">
      <div className="upper">
        <div className="search">
          {/* <label htmlFor="search">
            <FontAwesomeIcon icon={faMagnifyingGlass}/>
          </label>
          <input type="text" name="search" id="search" onChange={(e)=>searchChange(e)} placeholder={t("hearder.searchPlaceholder")}/> */}
        </div>
        <div className="logo"><img src="/images/logo.png" alt="logo" /></div>
        <div className="icons">
          <div className="i" onClick={() => {setPrefActiveRef(true)}}>
            <FontAwesomeIcon icon={faGlobe}/>
          </div>
          <div className={`i ${userActiveRef.current && 'menuActive'}`} onClick={() => {setUserActiveRef(!userActiveRef.current)}}>
            <FontAwesomeIcon icon={faUser}/>
            <div className="menu">
              {authApi.isAuthenticated() ?
                <>
                  <Link to={t("url.accountWithId",{id:'info'})}><h3>{t('account.nav.info')}</h3></Link>
                  <Link to={t("url.support")}><h3>{t('support.title')}</h3></Link>
                </>
              :
                <>
                  <Link to={t("url.register")}><h3>{t('form.register.title')}</h3></Link>
                  <Link to={t("url.login")}><h3>{t('form.login.title')}</h3></Link>
                </>
              }
            </div>
          </div>
          <div className="i cartIcon" onClick={() => {setCartActiveRef(true)}}>
            <FontAwesomeIcon icon={faCartShopping}/>
            <div className="counter">
              {cart.products}
            </div>
          </div>
        </div>
      </div>
      <div className="lower">
        <nav>
          <div className="link"><Link to="/">{t("header.nav.home")}</Link></div>
          <div className={`selector link ${localeActiveRef.current?"active":""}`}>
            <div className="title" onClick={(ev)=>{ev.preventDefault();setLocaleActiveRef(!(localeActiveRef.current))}}>
              {t("header.nav.categories")}
            </div>
            <div className="selection">
              {categoriesRef.current.map((e,k) => {
                return <Link to={t('url.categoryWithId',{id:e.id})} key={`locale-${k}-${e}`} onClick={(ev)=>{setLocaleActiveRef(!(localeActiveRef.current))}}>{e.name[i18n.language]}</Link>
              })}
            </div>
          </div>
          <div className="link"><Link  to={t('url.productList')}>{t("header.nav.products")}</Link></div>
          <div className="link"><Link to={t('url.contact')}>{t("header.nav.contact")}</Link></div> 
        </nav>
      </div>
    </div>
  </div>)
}
export default Nav