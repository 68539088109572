import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useRef, useState } from "react"
import { faEnvelope } from "@fortawesome/free-regular-svg-icons"
import { faHouse } from "@fortawesome/free-solid-svg-icons"
import { fri9g_notify } from "fri9g-notification-react"

const Contact = (props) => {
  const {t} = props

  const [username,setUsername] = useState("")
  const usernameRef = useRef(username)
  const setUsernameRef = (data) => {
    usernameRef.current = data
    setUsername(data)
  }

  const [message,setMessage] = useState("")
  const messageRef = useRef(message)
  const setMessageRef = (data) => {
    messageRef.current = data
    setMessage(data)
  }

  const handleSubmit = (ev) => {
    ev.preventDefault()
    fri9g_notify.success(t('form.contact'))
  }

  return <div className="contact">
    <div className="infos">
      <div className="grid">
        <FontAwesomeIcon icon={faEnvelope}/>
        <p>contact@fri9g.com</p>
        <FontAwesomeIcon icon={faHouse}/>
        <p>Rue des Tongs, 94<br/>
          4269 Soundboard City<br/>
          Belgium
        </p>
      </div>
    </div>
    <div className="form">
      <div className="container">
        <div className="formUnit">
          <h5>{t("form.username")}</h5>
          <input type="text" value={usernameRef.current} onChange={ev => {ev.preventDefault();setUsernameRef(ev.target.value)}}/>
        </div>
        <div className="formUnit message">
          <h5>{t("form.message")}</h5>
          <textarea value={messageRef.current} onChange={ev => {ev.preventDefault();setMessageRef(ev.target.value)}}/>
        </div>
        <button onClick={ev => handleSubmit(ev)}>
          {t("form.submit")}
        </button>
      </div>
    </div>
  </div>
} 
export default Contact