import { useEffect, useRef, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faXmark } from "@fortawesome/free-solid-svg-icons"
import Axios from 'axios'
import config from "../config/api.config"
import Loading from "./Loading"
import { faTrashCan } from "@fortawesome/free-regular-svg-icons"
import { fri9g_notify } from "fri9g-notification-react"
import authApi from "../services/authAPI"

const Cart = (props) => {
  const {t,i18n,price,currencyRef,cartRef,setCartRef,cartActiveRef,setCartActiveRef} = props

  const [products,setProducts] = useState({})
  const productsRef = useRef(products)
  const setProductsRef = (data) => {
    productsRef.current = data
    setProducts(data)
  }

  const [loading,setLoading] = useState(true)
  const loadingRef = useRef(loading)
  const setLoadingRef = (data) => {
    loadingRef.current = data
    setLoading(data)
  }

  

  const checkLoad = () => {
    let p = {...productsRef.current}
    for (let i = 0; i < cartRef.current.cart.length; i++) {
      const e = cartRef.current.cart[i]
      if(!p[e.id]) return false
    }
    setLoadingRef(false)
  }

  const findPic = (e) => {
    let el = productsRef.current[e.id]
    let picId = el.order_picture[0]
    if(!Array.isArray(el.order_picture)) picId = [el.order_picture][0]
    console.log(el,picId)
    for (let index = 0; index < el.pictures.length; index++) {
      const element = el.pictures[index]
      console.log(element,picId)
      if(element.id === picId) return <img src={`${config.api}images/xs/${element.file}`} alt={e.name} />
    }
    return null
  }

  const findOption = (e) => {
    let el = productsRef.current[e.id]
    let opt = e.option.split('-')
    let r = ""
    if(opt.length===1 && opt[0]==="DEFAULT") return t(`option.DEFAULT`)
    for (let index = 0; index < opt.length; index++) {
      const element = opt[index]
      let oe = el.option[element]
      r += t(`option.${oe.name}`)
      if(index !== opt.length-1) r += " - "
    }
    return r
  }

  const removeCart = (k) => {
    let c = {...cartRef.current}
    let e = c.cart[k]
    c.products = c.products - e.quantity
    c.cart.splice(k,1)
    setCartRef(c)
    fri9g_notify.warning(t("cart.item.removed"))
  }

  const cartTotal = () => {
    let c = {...cartRef.current}
    if(c.products === 0) return `${(0).toFixed(2)} ${currencyRef.current.symbol}`
    let t = []
    for (let index = 0; index < c.cart.length; index++) {
      const element = c.cart[index]
      if(productsRef.current[element.id]) t.push(price(productsRef.current[element.id].price,productsRef.current[element.id].currency,element.quantity))
    }
    let to = 0
    for (let index = 0; index < t.length; index++) {
      const element = t[index]
      to += parseFloat(element.split(' ')[0])
    }
    return `${to.toFixed(2)} ${currencyRef.current.symbol}`
  }

  const cartOrder = (ev) => {
    ev.preventDefault()
    if(authApi.isAuthenticated()) {
      setCartRef({products:0,cart:[]})
      setCartActiveRef(false)
      fri9g_notify.warning(t("cart.ordered"))
    } else {
      fri9g_notify.error(t("auth.credentials.missing"))
    }
    
  }

  useEffect(()=>{
    setLoadingRef(true)
    for (let i = 0; i < cartRef.current.cart.length; i++) {
      const e = cartRef.current.cart[i]
      Axios.get(`${config.api}p/product/${e.id}?locale=${i18n.language}`).then(resp => {
        let p = {...productsRef.current}
        p[e.id] = resp.data
        setProductsRef(p)
        checkLoad()
      })
    }
    if(cartRef.current.products === 0) checkLoad()
  },[cartRef.current,i18n.language,cartRef.current.products])

  return <div className={`cart ${cartActiveRef.current ? 'active' : ''}`}>
    <div className="filter"></div>
    <div className="container">
      <div className="cartHeader">
        <h1>{t("cart.title")}</h1>
        <h3>{t("cart.products",{nb:cartRef.current.products})}</h3>
        <FontAwesomeIcon icon={faXmark} onClick={()=>{setCartActiveRef(false)}}/>
      </div>
      <div className="cartBody">
        {loadingRef.current ?
        <Loading/>
        :
        <>
          {cartRef.current.cart.map((e,k)=><div key={`cart-${e}-${k}`}>
            {productsRef.current[e.id] && <div className="productUnit">
              {console.log(productsRef.current[e.id])}
              <div className="pic">
                {findPic(e)}
              </div>
              <div className="infos">
                <h4 className="nom">{productsRef.current[e.id].name[i18n.language]}</h4>
                <h4 className="price">{price(productsRef.current[e.id].price,productsRef.current[e.id].currency,e.quantity)}</h4>
                <div className="subinfo">
                  <h4>{findOption(e)}</h4>
                  <h4>{t("cart.quantitySub",{nb:e.quantity})}</h4>
                </div>
                <FontAwesomeIcon onClick={ev => removeCart(k)} icon={faTrashCan}/>
              </div>
            </div>}
          </div>)}
        </>}
      </div>
      <div className="cartFooter">
        <h3>{t("cart.total")}</h3>{loadingRef.current ?
          <Loading/>
          :<h3>{cartTotal()}</h3>}
        <button onClick={ev => {cartOrder(ev)}}>{t("cart.commander")}</button>
      </div>
    </div>
  </div>
}
export default Cart