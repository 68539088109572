import Axios from "axios"
import { useEffect, useRef, useState } from "react"
import config from "../config/api.config"
import Loading from "../components/Loading"
import { fri9g_notify } from "fri9g-notification-react"
import authApi from "../services/authAPI"
import moment from "moment"
import { useNavigate } from "react-router-dom"

const SupportRequest = (props) => {
  const {t,i18n,selectedRef,setSelectedRef,id} = props
  const formatDate = (str) => moment(str).locale(i18n.language).format('L - LT')
  const typeSelection = ['order','product','technical','suggestion','others']
  const navigate = useNavigate()

  const [support,setSupport] = useState({})
  const supportRef = useRef(support)
  const setSupportRef = (data) => {
    supportRef.current = data
    setSupport(data)
  }

  const [type,setType] = useState(typeSelection[0])
  const typeRef = useRef(type)
  const setTypeRef = (data) => {
    typeRef.current = data
    setType(data)
  }

  const [message,setMessage] = useState("")
  const messageRef = useRef(message)
  const setMessageRef = (data) => {
    messageRef.current = data
    setMessage(data)
  }

  const [subject,setSubject] = useState("")
  const subjectRef = useRef(subject)
  const setSubjectRef = (data) => {
    subjectRef.current = data
    setSubject(data)
  }

  const [loading,setLoading] = useState(true)
  const loadingRef = useRef(loading)
  const setLoadingRef = (data) => {
    loadingRef.current = data
    setLoading(data)
  }
  
  const [messageLoading,setMessageLoading] = useState(false)
  const messageLoadingRef = useRef(messageLoading)
  const setMessageLoadingRef = (data) => {
    messageLoadingRef.current = data
    setMessageLoading(data)
  }

  const [localeActive,setLocaleActive] = useState(false)
  const localeActiveRef = useRef(localeActive)
  const setLocaleActiveRef = (data) => {
    localeActiveRef.current = data
    setLocaleActive(data)
  }

  useEffect(()=>{
    setLoadingRef(true)
    setMessageRef("")
    setSubjectRef("")
    setTypeRef(typeSelection[0])
    setSupportRef({})
    if(typeof id !== 'undefined' && id!=='new'){
      Axios.get(`${config.api}user/${authApi.get("id")}/supports/${id}?locale=${i18n.language}`).then(resp => {
        setSupportRef({message:(resp.data.message).reverse(),...resp.data})
        setLoadingRef(false)
      }).catch(err => {
        if(err?.response?.status === 403) {
          if(authApi.isAuthenticated()) {
            fri9g_notify.error(t("auth.credentials.notAuthorized"))
            setSelectedRef(null)
          }
          else fri9g_notify.error(t("auth.credentials.missing"))
        }
        else fri9g_notify.error(t("general.internalError"))
      })
    } else {
      setLoadingRef(false)
    }
  },[i18n.language,id])

  const handleSubmit = async (ev) => {
    ev.preventDefault()
    if(messageLoadingRef.current) return
    console.log(selectedRef.current)
    setMessageLoadingRef(true)
    if(typeof id !== 'undefined' && id!=='new') {
      await Axios.post(`${config.api}supports/answer?locale=${i18n.language}`,{request:selectedRef.current,message:messageRef.current}).then(resp => {
        setSupportRef(resp.data)
        setMessageRef("")
        setMessageLoadingRef(false)
      })
    } else if(typeof id !== 'undefined' && id==='new') {
      await Axios.post(`${config.api}supports/new?locale=${i18n.language}`,{type:typeRef.current,message:messageRef.current,subject:subjectRef.current}).then(resp => {
        setSupportRef(resp.data)
        setMessageRef("")
        setSubjectRef("")
        setTypeRef(typeSelection[0])
        setMessageLoadingRef(false)
        navigate(t('url.supportRequestWithId',{id:resp.data.id}))
      })
    }
    
  }

  return <div className="supportRequest">
    {loadingRef.current ? <Loading/> : <>
      {typeof id !== 'undefined' && id!== "new" && <div className="container">
        <div className="messages">
          {supportRef.current.message && supportRef.current.message.map((e,k)=> <div className={`message ${e.user.id===authApi.get('id') ? 'user' : 'admin'}`} key={`message-${k}-${e.date}`}>
            <p>{e.message}</p>
            <h6>{formatDate(e.date)}</h6>
            <h5>{e.user.username}</h5>
          </div>)}
        </div>
        <div className="sendBox">
          <div className="formUnit">
            <h5>{t("form.message")}</h5>
            <textarea value={messageRef.current} onChange={ev => {ev.preventDefault();setMessageRef(ev.target.value)}}/>
          </div>
          <button onClick={ev => handleSubmit(ev)} disabled={messageLoadingRef.current}>{messageLoadingRef.current ? <Loading/> : t("form.submit")}</button>
        </div>
      </div>}
      {typeof id !== 'undefined' && id === 'new' && <div className="form">
        <div className="container">
          <div className="formUnit">
            <div className={`selector ${localeActiveRef.current ? 'active' : ''}`}>
              <div className="title" onClick={(ev)=>{ev.preventDefault();setLocaleActiveRef(!(localeActiveRef.current))}}>
                {t(`support.type.${typeRef.current}`)}
              </div>
              <div className="selection">
                {typeSelection.map((e,k) => {
                  if(e !== typeRef.current)return <div key={`locale-${k}-${e}`} onClick={(ev)=>{setTypeRef(e);setLocaleActiveRef(!(localeActiveRef.current))}}>{t(`support.type.${e}`)}</div>
                })}
              </div>
            </div>
            <h5>{t("support.type.label")}</h5>
          </div>
          <div className="formUnit">
            <h5>{t("support.subject")}</h5>
            <input type="text" value={subjectRef.current} onChange={ev => {ev.preventDefault();setSubjectRef(ev.target.value)}}/>
          </div>
          <div className="formUnit message">
            <h5>{t("form.message")}</h5>
            <textarea value={messageRef.current} onChange={ev => {ev.preventDefault();setMessageRef(ev.target.value)}}/>
          </div>
          <button onClick={ev => handleSubmit(ev)} disabled={messageLoadingRef.current}>
            {messageLoadingRef.current ? <Loading/> : t("form.submit")}
          </button>
        </div>
      </div>}
      {typeof id === 'undefined' && <div className="container">
        <h1>{t("support.noSelected")}</h1>
      </div>}
    </>}
  </div>
}
export default SupportRequest