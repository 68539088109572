import { Link, useNavigate } from "react-router-dom"
import authApi from "../services/authAPI"

const AccountNav = (props) => {
  const {t,page} = props
  const navigate = useNavigate()
  
  const logout = (ev) => {
    ev.preventDefault()
    authApi.logout()
    navigate("/")
  }

  return <div className="accountNav">
    <nav>
      <Link className={page===t("url.accountInfo")?"active":""} to={t("url.accountWithId",{id:t("url.accountInfo")})}>{t("account.nav.info")}</Link>
      <Link className={page===t("url.accountOrder")?"active":""} to={t("url.accountWithId",{id:t("url.accountOrder")})}>{t("account.nav.order")}</Link>
      <Link className={page===t("url.accountSupport")?"active":""} to={t("url.accountWithId",{id:t("url.accountSupport")})}>{t("account.nav.support")}</Link>
    </nav>
    <button onClick={ev=>logout(ev)}>{t("account.nav.logout")}</button>
  </div>
}
export default AccountNav