import moment from "moment"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope } from "@fortawesome/free-regular-svg-icons"
import { faHouse } from "@fortawesome/free-solid-svg-icons"

const Footer = (props) => {
  const {t} = props
  const formatDate = (str) => moment(str).format('YYYY')

  return <div className="footer">
    <div className="container">
      <div className="contactFoot">
        <h2>{t("footer.contact.title")}</h2>
        <div className="grid">
          <FontAwesomeIcon icon={faEnvelope}/>
          <p>contact@fri9g.com</p>
          <FontAwesomeIcon icon={faHouse}/>
          <p>Rue des Tongs, 94<br/>
            4269 Soundboard City<br/>
            Belgium
          </p>
        </div>
      </div>
      <div className="menu">
        <h2>{t("footer.menu.title")}</h2>
        <p><Link>{t("footer.menu.cgv")}</Link></p>
        <p><Link>{t("footer.menu.pdc")}</Link></p>
        <p><Link to={t('url.contact')}>{t("footer.menu.contact")}</Link></p>
        <p><Link to={t('url.support')}>{t("footer.menu.support")}</Link></p>
      </div>
    </div>
    <div className="footer-copy-right">
      <h2>&copy; <Link to="https://www.fri9g.com">{ formatDate() } Fri9g</Link></h2>
    </div>
  </div>
}

export default Footer
