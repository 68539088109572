import Axios from "axios"
import { useEffect, useRef, useState } from "react"
import { Link, useParams } from "react-router-dom"
import Loading from "../components/Loading"
import config from "../config/api.config"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown, faChevronLeft } from "@fortawesome/free-solid-svg-icons"

const ProductList = (props) => {
  const {t,i18n,price,categoriesRef} = props
  const {id} = useParams()
  const orderList = ["id","A-Z","Z-A","priceUp","priceDown"]

  const [products,setProducts] = useState([])
  const productsRef = useRef(products)
  const setProductsRef = (data) => {
    productsRef.current = data
    setProducts(data)
  }

  const [loading,setLoading] = useState(true)
  const loadingRef = useRef(loading)
  const setLoadingRef = (data) => {
    loadingRef.current = data
    setLoading(data)
  }

  const [order,setOrder] = useState("id")
  const orderRef = useRef(order)
  const setOrderRef = (data) => {
    orderRef.current = data
    setOrder(data)
  }

  const [orderActive,setOrderActive] = useState(false)
  const orderActiveRef = useRef(orderActive)
  const setOrderActiveRef = (data) => {
    orderActiveRef.current = data
    setOrderActive(data)
  }

  useEffect(()=>{
    setLoadingRef(true)
    if(id) {
      Axios.get(`${config.api}p/products/${id}?locale=${i18n.language}`).then(resp => {
        setProductsRef(resp.data)
        setLoadingRef(false)
      })
    } else {
      Axios.get(`${config.api}p/products?locale=${i18n.language}`).then(resp => {
        setProductsRef(resp.data)
        setLoadingRef(false)
      })
    }
    
  },[id,i18n.language])

  return (loadingRef.current ? 
    <Loading/>
  :<div className="productList">
    <div className="container">
      <h1>{id ?
        <>
          {categoriesRef.current.map((e,k)=>{
            if(e.id===parseInt(id)) return <span key={`cat-${e.id}-${k}`}>{(e.name[i18n.language]).normalize("NFD").replace(/[\u0300-\u036f]/g, "")}</span>
            return null
          })}
        </>
      : t('productList.title')
      }</h1>
      {/* <div className="actionBar">
        <div className={`selector ${orderActiveRef.current?"active":""}`}>
          <div className="title" onClick={(ev)=>{ev.preventDefault();setOrderActiveRef(!(orderActiveRef.current))}}>
            <h4>{t(`productList.order.${orderRef.current}`)}</h4>
            {orderActiveRef.current?<FontAwesomeIcon icon={faChevronLeft}/>:<FontAwesomeIcon icon={faChevronDown}/>}
          </div>
          <div className="selection">
            {orderList.map((e,k) => {
              if(e===orderRef.current) return null
              return <h4 key={`locale-${k}-${e}`} onClick={()=>{setOrderRef(e);setOrderActiveRef(false)}}>{t(`productList.order.${e}`)}</h4>
            })}
          </div>
        </div>
      </div> */}
      <div className="grid">
        {productsRef.current.map((e,k)=><Link className="productUnit" to={t("url.productWithId",{id:e.id})} key={`product-${e.id}-${k}`}>
          <div className="pic">
            <img src={`${config.api}images/l/${e.cover.file}`} alt={`pictures of ${e.name}`} />
          </div>
          <h4 className="name">{((e.name).normalize("NFD").replace(/[\u0300-\u036f]/g, "")).slice(0,47)+(e.name.length > 47 ? '...' : '')}</h4>
          <h4 className="price">{price(e.price,e.currency)}</h4>
        </Link>)}
      </div>
    </div>
  </div>
  )
}
export default ProductList