import { faBomb } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect } from "react"
import { Link } from "react-router-dom"

const Page404 = (props) => {

  return (<div className="page e404">
    <FontAwesomeIcon icon={faBomb} />
    <h1>Page introuvable</h1>
    <p>Le lien que vous avez suivi ne correspond à aucunes de nos ressources. Si vous pensez que c'est une erreur contactez nous via la page contact afin de nous signaler le probleme. En attendant revenez à l'Accueil et recommencez une visite de zéro !</p>
    <p><Link to="/">Accueil</Link></p>
  </div>)
}

export default Page404