import { useEffect, useRef, useState } from "react"
import Caroussel from "../components/Caroussel"
import config from "../config/api.config"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBox, faHeadset, faLanguage, faWallet } from "@fortawesome/free-solid-svg-icons"
import Axios from "axios"
import Loading from "../components/Loading"

const Home = (props) => {

  const {t,i18n,price} = props
  const [loading,setLoading] = useState(true)
  const loadingRef = useRef(loading)
  const setLoadingRef = (data) => {
    loadingRef.current = data
    setLoading(data)
  }

  const [cd,setCd] = useState([{"id":14,"price":10900,"option_active":true,"option":{"KpY9FE4s":{"name":"PRESENT","type":"BOOLEAN","option":"PRESENT","display-type":"BOOLEAN","display-option":"","stock_dependant":false,"id":"KpY9FE4s"}},"stock":{"DEFAULT":100},"active":true,"currency":{"code":"JPY","symbol":"¥"},"category":{"id":3,"name":{"en":"CD","fr":"CD","ja":"シーディー"}},"name":"[Oshi No Ko] Idole Yoasobi","cover":{"id":4689,"file":"product-14-0-1719842146274-yoasobi.jpg"}},{"id":14,"price":10900,"option_active":true,"option":{"KpY9FE4s":{"name":"PRESENT","type":"BOOLEAN","option":"PRESENT","display-type":"BOOLEAN","display-option":"","stock_dependant":false,"id":"KpY9FE4s"}},"stock":{"DEFAULT":100},"active":true,"currency":{"code":"JPY","symbol":"¥"},"category":{"id":3,"name":{"en":"CD","fr":"CD","ja":"シーディー"}},"name":"[Oshi No Ko] Idole Yoasobi","cover":{"id":4689,"file":"product-14-0-1719842146274-yoasobi.jpg"}},{"id":14,"price":10900,"option_active":true,"option":{"KpY9FE4s":{"name":"PRESENT","type":"BOOLEAN","option":"PRESENT","display-type":"BOOLEAN","display-option":"","stock_dependant":false,"id":"KpY9FE4s"}},"stock":{"DEFAULT":100},"active":true,"currency":{"code":"JPY","symbol":"¥"},"category":{"id":3,"name":{"en":"CD","fr":"CD","ja":"シーディー"}},"name":"[Oshi No Ko] Idole Yoasobi","cover":{"id":4689,"file":"product-14-0-1719842146274-yoasobi.jpg"}},{"id":14,"price":10900,"option_active":true,"option":{"KpY9FE4s":{"name":"PRESENT","type":"BOOLEAN","option":"PRESENT","display-type":"BOOLEAN","display-option":"","stock_dependant":false,"id":"KpY9FE4s"}},"stock":{"DEFAULT":100},"active":true,"currency":{"code":"JPY","symbol":"¥"},"category":{"id":3,"name":{"en":"CD","fr":"CD","ja":"シーディー"}},"name":"[Oshi No Ko] Idole Yoasobi","cover":{"id":4689,"file":"product-14-0-1719842146274-yoasobi.jpg"}}])
  const cdRef = useRef(cd)
  const setCdRef = (data) => {
    cdRef.current = data
    setCd(data)
  }

  const [books,setBooks] = useState([{"id":1,"price":7.95,"option_active":true,"option":{"F8V96rLA":{"name":"PRESENT","type":"BOOLEAN","option":"PRESENT","display-type":"BOOLEAN","display-option":"","stock_dependant":false,"id":"F8V96rLA"}},"stock":{"DEFAULT":100},"active":true,"currency":{"code":"EUR","symbol":"€"},"category":{"id":1,"name":{"en":"Books","fr":"Livres","ja":"本"}},"name":"Les Carnets de l'apothicaire T13","cover":{"id":4650,"file":"product-1-0-1719842134423-apothicary.jpg"}},{"id":1,"price":7.95,"option_active":true,"option":{"F8V96rLA":{"name":"PRESENT","type":"BOOLEAN","option":"PRESENT","display-type":"BOOLEAN","display-option":"","stock_dependant":false,"id":"F8V96rLA"}},"stock":{"DEFAULT":100},"active":true,"currency":{"code":"EUR","symbol":"€"},"category":{"id":1,"name":{"en":"Books","fr":"Livres","ja":"本"}},"name":"Les Carnets de l'apothicaire T13","cover":{"id":4650,"file":"product-1-0-1719842134423-apothicary.jpg"}},{"id":1,"price":7.95,"option_active":true,"option":{"F8V96rLA":{"name":"PRESENT","type":"BOOLEAN","option":"PRESENT","display-type":"BOOLEAN","display-option":"","stock_dependant":false,"id":"F8V96rLA"}},"stock":{"DEFAULT":100},"active":true,"currency":{"code":"EUR","symbol":"€"},"category":{"id":1,"name":{"en":"Books","fr":"Livres","ja":"本"}},"name":"Les Carnets de l'apothicaire T13","cover":{"id":4650,"file":"product-1-0-1719842134423-apothicary.jpg"}}])
  const booksRef = useRef(books)
  const setBooksRef = (data) => {
    booksRef.current = data
    setBooks(data)
  }

  const [games,setGames] = useState([{"id":9,"price":49.99,"option_active":true,"option":{"RBjIZ80i":{"name":"PRESENT","type":"BOOLEAN","option":"PRESENT","display-type":"BOOLEAN","display-option":"","stock_dependant":false,"id":"RBjIZ80i"}},"stock":{"DEFAULT":100},"active":true,"currency":{"code":"USD","symbol":"$"},"category":{"id":2,"name":{"en":"VideoGames","fr":"JeuxVidéos","ja":"ビデオゲーム"}},"name":"Animal Crossing: New Horizons - Nintendo Switch","cover":{"id":4666,"file":"product-9-0-1719842139589-acnh01.jpg"}},{"id":9,"price":49.99,"option_active":true,"option":{"RBjIZ80i":{"name":"PRESENT","type":"BOOLEAN","option":"PRESENT","display-type":"BOOLEAN","display-option":"","stock_dependant":false,"id":"RBjIZ80i"}},"stock":{"DEFAULT":100},"active":true,"currency":{"code":"USD","symbol":"$"},"category":{"id":2,"name":{"en":"VideoGames","fr":"JeuxVidéos","ja":"ビデオゲーム"}},"name":"Animal Crossing: New Horizons - Nintendo Switch","cover":{"id":4666,"file":"product-9-0-1719842139589-acnh01.jpg"}}])
  const gamesRef = useRef(games)
  const setGamesRef = (data) => {
    gamesRef.current = data
    setGames(data)
  }


  useEffect(()=>{
    setLoadingRef(true)
    Axios.get(`${config.api}p/homepage?locale=${i18n.language}`).then(resp => {
      setBooksRef((resp.data.books).slice(0,3))
      setCdRef((resp.data.cds).slice(0,4))
      setGamesRef((resp.data.games).slice(0,2))
      setLoadingRef(false)
    })
  },[i18n.language])

  return (loadingRef.current ? 
    <Loading/> : <div className="home">
    <Caroussel className="mainCaroussel" t={t} data={[
      {'src':'/images/games.png','alt':'games picture','key':'caroussel.games'},
      {'src':'/images/book.png','alt':'books picture','key':'caroussel.books'},
      {'src':'/images/garden.png','alt':'garden picture','key':'caroussel.garden'}
      ]}/>
    <div className="products">
      <div className="container">
      <div className="cd">
          <h1>{t("home.cd")}</h1>
          {cdRef.current.map((e,k)=><div className="element" key={"cd-"+k}>
            <div className="imageUnit">
              <img src={`${config.api}images/xs/${e.cover.file}`} alt={e.name} />
            </div>
            <h4>{e.name}</h4>
            <h5>{price(e.price,e.currency)}</h5>
          </div>)}
        </div>
        <div className="books">
          <h1>{t("home.books")}</h1>
          {booksRef.current.map((e,k)=><div className="element" key={"books-"+k}>
            <div className="imageUnit">
              <img src={`${config.api}images/l/${e.cover.file}`} alt={e.name} />
            </div>
            <h4>{e.name}</h4>
            <h5>{price(e.price,e.currency)}</h5>
          </div>)}
        </div>
        <div className="games">
          <h1>{t("home.games")}</h1>
          {gamesRef.current.map((e,k)=><div className="element" key={"games-"+k}>
            <div className="imageUnit">
              <img src={`${config.api}images/l/${e.cover.file}`} alt={e.name} />
            </div>
            <h4>{e.name}</h4>
            <h5>{price(e.price,e.currency)}</h5>
          </div>)}
        </div>
      </div>
    </div>
    <div className="pinnedNumbers">
      <div className="element">
        <FontAwesomeIcon icon={faBox}/>
        <h2>{t("home.pinned.fiability.title")}</h2>
        <pre>{t("home.pinned.fiability.text")}</pre>
      </div>
      <div className="element">
        <FontAwesomeIcon icon={faWallet}/>
        <h2>{t("home.pinned.currency.title")}</h2>
        <pre>{t("home.pinned.currency.text")}</pre>
      </div>
      <div className="element">
        <FontAwesomeIcon icon={faLanguage}/>
        <h2>{t("home.pinned.language.title")}</h2>
        <pre>{t("home.pinned.language.text")}</pre>
      </div>
      <div className="element">
        <FontAwesomeIcon icon={faHeadset}/>
        <h2>{t("home.pinned.services.title")}</h2>
        <pre>{t("home.pinned.services.text")}</pre>
      </div>
    </div>
    <div className="newsletter">
      <img src="/images/clothes.png" alt="clothes background for newsletter" />
      <div className="filter"/>
      <div className="container">
        <h2>{t("home.newsletter.title")}</h2>
        <pre>{t("home.newsletter.text")}</pre>
        <input type="email" placeholder={t("home.newsletter.placeholder")} />
        <button>{t("home.newsletter.button")}</button>
      </div>
    </div>
  </div>)
}

export default Home