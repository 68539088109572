import { useEffect, useRef, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faXmark } from "@fortawesome/free-solid-svg-icons"
import { fri9g_notify } from "fri9g-notification-react"
import { Route, Routes, useNavigate, useParams } from "react-router-dom"
import AccountInfo from "../components/AccountInfo"
import AccountOrder from "../components/AccountOrder"
import AccountSupport from "../components/AccountSupport"
import AccountNav from "../components/AccountNav"
import Page404 from "./Page404"
import authApi from "../services/authAPI"

const Account = (props) => {
  const {t,i18n} = props
  const {page} = useParams()
  const navigate = useNavigate()

  useEffect(()=>{
    if(!authApi.isAuthenticated()) navigate(t("url.login"))
  },[])

  const renderPage = () => {
    switch (page) {
      case t("url.accountInfo"):
        return <AccountInfo t={t} i18n={i18n}/>
      case t("url.accountOrder"): 
        return <AccountOrder t={t} i18n={i18n}/>
      case t("url.accountSupport"):
        return <AccountSupport t={t} i18n={i18n}/>
      default:
        return <Page404/>
    }
  }

  return <div className="account">
    <AccountNav t={t} page={page}/>
    {renderPage()}
  </div>
}
export default Account