import Axios from "axios"
import { useEffect, useRef, useState } from "react"
import config from "../config/api.config"
import Loading from "./Loading"
import { fri9g_notify } from "fri9g-notification-react"
import authApi from "../services/authAPI"
import moment from "moment"

const AccountOrder = (props) => {
  const {t,i18n} = props
  const formatDate = (str) => moment(str).locale(i18n.language).format('L LT')

  const [orders,setOrders] = useState([])
  const ordersRef = useRef(orders)
  const setOrdersRef = (data) => {
    ordersRef.current = data
    setOrders(data)
  }

  const [loading,setLoading] = useState(true)
  const loadingRef = useRef(loading)
  const setLoadingRef = (data) => {
    loadingRef.current = data
    setLoading(data)
  }

  useEffect(()=>{
    Axios.get(`${config.api}user/${authApi.get("id")}/orders?locale=${i18n.language}`).then(resp => {
      setOrdersRef((resp.data.order).reverse())
      setLoadingRef(false)
    }).catch(err => {
      if(err?.response?.status === 403) fri9g_notify.error(t("auth.credentials.missing"))
      else fri9g_notify.error(t("general.internalError"))
    })
  },[i18n.language])

  const countProd = (e) => {
    let p = 0
    for (let i = 0; i < e.cart.length; i++) {
      const element = e.cart[i]
      p += element.quantity
    }
    return p
  }

  return <div className="orders">{loadingRef.current ? <Loading /> : <>
      {ordersRef.current.map((e,k)=><div className="order">
        <div className="date">{formatDate(e.createdAt)}</div>
        <div className="count">{t('cart.products',{nb:countProd(e)})}</div>
        <div className="amount">{`${(e.amount).toFixed(2)} ${e.currency.symbol}`}</div>
        <div className={`status ${e.status}`}>{t(`order.status.${e.status}`)}</div>
      </div>)}
    </>}
  </div>

}
export default AccountOrder