import { fri9g_notify } from "fri9g-notification-react"
import { useEffect, useRef, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import config from "../config/api.config"
import Axios from "axios"
const Register = (props) => {
  const {t,i18n} = props
  const navigate = useNavigate()

  const [username,setUsername] = useState("")
  const usernameRef = useRef(username)
  const setUsernameRef = (data) => {
    usernameRef.current = data
    setUsername(data)
  }

  const [email,setEmail] = useState("")
  const emailRef = useRef(email)
  const setEmailRef = (data) => {
    emailRef.current = data
    setEmail(data)
  }
  const [password,setPassword] = useState("")
  const passwordRef = useRef(password)
  const setPasswordRef = (data) => {
    passwordRef.current = data
    setPassword(data)
  }

  const [passwordConfirm,setPasswordConfirm] = useState("")
  const passwordConfirmRef = useRef(passwordConfirm)
  const setPasswordConfirmRef = (data) => {
    passwordConfirmRef.current = data
    setPasswordConfirm(data)
  }

  const [loading,setLoading] = useState(false)
  const loadingRef = useRef(loading)
  const setLoadingRef = (data) => {
    loadingRef.current = data
    setLoading(data)
  }

  const handleSubmit = (ev) => {
    ev.preventDefault()
    if(passwordRef.current!==passwordConfirmRef.current) {
      fri9g_notify.error("form.login.passwordFailed")
      return
    }
    setLoadingRef(true)
    Axios.post(`${config.api}auth/register`,{username:usernameRef.current,email:emailRef.current,password:passwordRef.current}).then(resp => {
      fri9g_notify.success("form.register.success")
      navigate(t("url.login"))
    }).catch(err => {
      if(err.response?.data?.code) fri9g_notify.error(t(err.response.data.code))
      else fri9g_notify.error("form.register.failed")
    })

  }

  return <div className="form register">
    <div className="container">
      <h1>{t("form.register.title")}</h1>
      <div className="formUnit">
        <h5>{t("form.username")}</h5>
        <input type="text" value={usernameRef.current} onChange={ev => {ev.preventDefault();setUsernameRef(ev.target.value)}}/>
      </div>
      <div className="formUnit">
        <h5>{t("form.email")}</h5>
        <input type="text" value={emailRef.current} onChange={ev => {ev.preventDefault();setEmailRef(ev.target.value)}}/>
      </div>
      <div className="formUnit">
        <h5>{t("form.password")}</h5>
        <input type="password" value={passwordRef.current} onChange={ev => {ev.preventDefault();setPasswordRef(ev.target.value)}}/>
        <h6>{t("form.passwordReq")}</h6>
      </div>
      <div className="formUnit">
        <h5>{t("form.password")}</h5>
        <input type="password" value={passwordConfirmRef.current} onChange={ev => {ev.preventDefault();setPasswordConfirmRef(ev.target.value)}}/>
      </div>
      <button onClick={ev => handleSubmit(ev)}>
        {t("form.register.button")}
      </button>
      <div className="formUnit small">
        <Link><h6>{t("form.register.alreadyAcc")}</h6></Link>
        <div></div>
      </div>
    </div>
  </div>
}
export default Register