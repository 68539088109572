import Axios from 'axios'
import config from '../config/api.config'
import { Buffer } from 'buffer'
import { fri9g_notify } from 'fri9g-notification-react'

function logout() {
  window.localStorage.removeItem("QYwgeuLwL3d7nsAWwn9xPmPFyqwJW7g2GcJewrChpXQhezNAJBAAYvC5uXpM9hq3")
  delete Axios.defaults.headers['Authorization']
}

async function login(username,password,remember=false){
  try {
    var resp = await Axios.post(config.login,{username,password})
  } catch (error) {
    fri9g_notify.error(error.response?.data?.code)
    return null
  }
  if(!resp.data.QYwgeuLwL3d7nsAWwn9xPmPFyqwJW7g2GcJewrChpXQhezNAJBAAYvC5uXpM9hq3) {
    return false
  }
  window.localStorage.setItem("QYwgeuLwL3d7nsAWwn9xPmPFyqwJW7g2GcJewrChpXQhezNAJBAAYvC5uXpM9hq3",resp.data.QYwgeuLwL3d7nsAWwn9xPmPFyqwJW7g2GcJewrChpXQhezNAJBAAYvC5uXpM9hq3)
  Axios.defaults.headers["Authorization"] = "Bearer "+resp.data.QYwgeuLwL3d7nsAWwn9xPmPFyqwJW7g2GcJewrChpXQhezNAJBAAYvC5uXpM9hq3
  return true
}

function setup(){
  const token = window.localStorage.getItem("QYwgeuLwL3d7nsAWwn9xPmPFyqwJW7g2GcJewrChpXQhezNAJBAAYvC5uXpM9hq3")
  if(token) {
    const jwtData = JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString())
    // const jwtData = jsonwebtoken.decode(token)
    if((jwtData.exp * 1000)>new Date().getTime()) {
      Axios.defaults.headers["Authorization"] = "Bearer "+token
    }
  }
}

function isAuthenticated() {
  const token = window.localStorage.getItem("QYwgeuLwL3d7nsAWwn9xPmPFyqwJW7g2GcJewrChpXQhezNAJBAAYvC5uXpM9hq3")
  if(token) {
    const jwtData = JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString())
    // const jwtData = jsonwebtoken.decode(token)
    if(jwtData.role_weight===0) return false
    if((jwtData.exp * 1000)>new Date().getTime()) {
      return true
    }
  }
  return false
}

function isGranted(role) {
  const token = window.localStorage.getItem("QYwgeuLwL3d7nsAWwn9xPmPFyqwJW7g2GcJewrChpXQhezNAJBAAYvC5uXpM9hq3")
  if(token) {
    const jwtData = JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString())
    // const jwtData = jsonwebtoken.decode(token)
    if(role in jwtData.roles) {
      return true
    }
  }
  return false
}

function get(key) {
  const token = window.localStorage.getItem("QYwgeuLwL3d7nsAWwn9xPmPFyqwJW7g2GcJewrChpXQhezNAJBAAYvC5uXpM9hq3")
  if(token) {
    const jwtData = JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString())
    // const jwtData = jsonwebtoken.decode(token)
    if(jwtData[key]) {
      return jwtData[key]
    }
  }
  return null
}

const authApi = {
  logout: logout,
  login: login,
  setup: setup,
  isAuthenticated: isAuthenticated,
  isGranted: isGranted,
  get: get
}
export default authApi