import Axios from "axios"
import { useEffect, useRef, useState } from "react"
import config from "../config/api.config"
import Loading from "../components/Loading"
import { fri9g_notify } from "fri9g-notification-react"
import authApi from "../services/authAPI"
import SupportNav from "../components/SupportNav"
import { useNavigate, useParams } from "react-router-dom"
import SupportRequest from "../components/SupportRequest"

const Support = (props) => {
  const {t,i18n} = props
  const navigate = useNavigate()
  const {id} = useParams()

  const [selected,setSelected] = useState(null)
  const selectedRef = useRef(selected)
  const setSelectedRef = (data) => {
    selectedRef.current = data
    setSelected(data)
  }


  useEffect(() => {
    if(!authApi.isAuthenticated()) navigate(t("url.login"))
    if(id) setSelectedRef(parseInt(id))
  },[id])

  return <div className="supportPage">
    <SupportNav t={t} i18n={i18n} id={id} selectedRef={selectedRef} setSelectedRef={setSelectedRef}/>
    <SupportRequest id={id} t={t} i18n={i18n} selectedRef={selectedRef} setSelectedRef={setSelectedRef}/>
  </div>
}
export default Support