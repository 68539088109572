import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom"
import './css/App.css'
import moment from "moment"
import { useEffect, useRef, useState } from "react"
import Home from './pages/Home'
import Page404 from "./pages/Page404"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown } from "@fortawesome/free-solid-svg-icons"
import { Fri9gNotifier,fri9g_notify } from 'fri9g-notification-react'
import 'fri9g-notification-react/dist/index.css'
import Axios from 'axios'

import './i18n'
import { useTranslation } from "react-i18next"
import Nav from "./components/Nav"
import Footer from "./components/Footer"
import Product from "./pages/Product"
import config from "./config/api.config"
import Loading from "./components/Loading"
import ProductList from "./pages/ProductList"
import Cart from "./components/Cart"
import Login from "./pages/Login"
import Register from "./pages/Register"
import Preferences from "./components/Preferences"
import Contact from "./pages/Contact"
import authApi from "./services/authAPI"
import Account from "./pages/Account"
import Support from "./pages/Support"
import Order from "./pages/Order"


Axios.defaults.headers.common['Access-Control-Allow-Origin'] = `https://frishopping.fri9g.com`

function App() {
  const { t, i18n } = useTranslation("translations")

  const [initialData,setInitialData] = useState([])
  const initialDataRef = useRef(initialData)
  const setInitialDataRef = (data) => {
    initialDataRef.current = data
    setInitialData(data)
  }

  const [currency,setCurrency] = useState({"code":"EUR","symbol":"€"})
  const currencyRef = useRef(currency)
  const setCurrencyRef = (data) => {
    currencyRef.current = data
    setCurrency(data)
  }

  const [categories,setCategories] = useState([])
  const categoriesRef = useRef(categories)
  const setCategoriesRef = (data) => {
    categoriesRef.current = data
    setCategories(data)
  }
  
  const [cart,setCart] = useState({
    products:0,
    cart:[]
  })
  const cartRef = useRef(cart)
  const setCartRef = (data) => {
    cartRef.current = data
    localStorage.setItem("eS5zrF9sCXhdtRZH",JSON.stringify(data))
    setCart(data)
  }

  const [cartActive,setCartActive] = useState(false)
  const cartActiveRef = useRef(cartActive)
  const setCartActiveRef = (data) => {
    cartActiveRef.current = data
    setCartActive(data)
  }

  const [prefActive,setPrefActive] = useState(false)
  const prefActiveRef = useRef(prefActive)
  const setPrefActiveRef = (data) => {
    prefActiveRef.current = data
    setPrefActive(data)
  }

  const [loading,setLoading] = useState(true)
  const loadingRef = useRef(loading)
  const setLoadingRef = (data) => {
    loadingRef.current = data
    setLoading(data)
  }

  const price = (a,c,q=1) => {
    a = a * q
    if(currencyRef.current.code === c.code) return `${a.toFixed(2)} ${currencyRef.current.symbol}`
    let ir = initialDataRef.current?.currencies_rates[`EUR-${c.code}`]
    if(!ir) return `${a} ${c.symbol}`
    let b = Math.ceil((a / ir)*100)/100
    if(currencyRef.current.code === "EUR") return `${b.toFixed(2)} ${currencyRef.current.symbol}`
    let or = initialDataRef.current?.currencies_rates[`EUR-${currencyRef.current.code}`]
    if(!or) return `${b.toFixed(2)} ${c.symbol}`
    return `${(Math.ceil((b * or)*100)/100).toFixed(2)} ${currencyRef.current.symbol}`
  }

  useEffect(()=>{
    if((i18n.language).split('-').length === 2 ) i18n.changeLanguage((i18n.language).split('-')[0])
    authApi.setup()
    let c = JSON.parse(localStorage.getItem("eS5zrF9sCXhdtRZH"))
    if(c!==null) setCartRef(c)
    Axios.get(`${config.api}auth/initial`).then(resp=>{
      setInitialDataRef(resp.data)
      let locales = resp.data.locales
      for (let i = 0; i < locales.length; i++) {
        const element = locales[i]
        if(element.iso !== 'en')import(`moment/locale/${element.iso}`).then(f => moment.updateLocale(element.iso,f))
      }
      Axios.get(`${config.api}p/category/all`).then(respp => {
        setCategoriesRef(respp.data)
        setLoadingRef(false)
      })
    })
    moment.locale(i18n.language)
  },[])


  return ( loadingRef.current ? 
      <Loading/>
    : <>
      <Router>
        <div className="App">
          <Nav t={t} i18n={i18n} cart={cart} categoriesRef={categoriesRef} setCartActiveRef={setCartActiveRef} setPrefActiveRef={setPrefActiveRef}/>
          <Routes>
            <Route path="/" element={<Home t={t} i18n={i18n} price={price}/>}/>
            <Route path={t("url.product")} element={<Product t={t} i18n={i18n} price={price} cartRef={cartRef} setCartRef={setCartRef}/>}/>
            <Route path={t("url.productList")} element={<ProductList t={t} i18n={i18n} price={price}/>}/>
            <Route path={t("url.category")} element={<ProductList t={t} i18n={i18n} price={price} categoriesRef={categoriesRef}/>}/>
            <Route path={t("url.login")} element={<Login t={t} i18n={i18n}/>}/>
            <Route path={t("url.register")} element={<Register t={t} i18n={i18n}/>}/>
            <Route path={t("url.contact")} element={<Contact t={t} i18n={i18n}/>}/>
            <Route path={t("url.account")} element={<Account t={t} i18n={i18n}/>}/>
            <Route path={t("url.support")} element={<Support t={t} i18n={i18n}/>}/>
            <Route path={t("url.supportRequest")} element={<Support t={t} i18n={i18n}/>}/>
            <Route path={t("url.order")} element={<Order t={t} i18n={i18n}/>}/>
            <Route path='*' element={<Page404/>}/>
          </Routes>
          <Footer t={t}/>
          <Cart t={t} i18n={i18n} price={price} currencyRef={currencyRef} cartActiveRef={cartActiveRef} setCartActiveRef={setCartActiveRef} cartRef={cartRef} setCartRef={setCartRef}/>
          <Preferences t={t} i18n={i18n} setPrefActiveRef={setPrefActiveRef} prefActiveRef={prefActiveRef} initialDataRef={initialDataRef} currencyRef={currencyRef} setCurrencyRef={setCurrencyRef} />
        </div>
      </Router>
      <Fri9gNotifier position={fri9g_notify.POSITION.BOTTOM_LEFT}/>
    </>
  )
}

export default App;
