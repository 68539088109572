import Axios from "axios"
import { useEffect, useRef, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import config from "../config/api.config"
import authApi from "../services/authAPI"
import { fri9g_notify } from "fri9g-notification-react"
const Login = (props) => {
  const {t,i18n,price} = props
  const navigate = useNavigate()

  const [username,setUsername] = useState([])
  const usernameRef = useRef(username)
  const setUsernameRef = (data) => {
    usernameRef.current = data
    setUsername(data)
  }

  const [password,setPassword] = useState([])
  const passwordRef = useRef(password)
  const setPasswordRef = (data) => {
    passwordRef.current = data
    setPassword(data)
  }

  const [loading,setLoading] = useState(false)
  const loadingRef = useRef(loading)
  const setLoadingRef = (data) => {
    loadingRef.current = data
    setLoading(data)
  }

  const handleSubmit = (ev) => {
    ev.preventDefault()
    setLoadingRef(true)
    authApi.login(usernameRef.current,passwordRef.current).then(resp => {
      if(resp) {
        fri9g_notify.success("form.login.success")
        navigate("/")
      } else if(resp===false) {
        fri9g_notify.error("form.login.failed")
      }
    })
  }

  return <div className="form login">
    <div className="container">
      <h1>{t("form.login.title")}</h1>
      <div className="formUnit">
        <h5>{t("form.username")}</h5>
        <input type="text" value={usernameRef.current} onChange={ev => {ev.preventDefault();setUsernameRef(ev.target.value)}}/>
      </div>
      <div className="formUnit">
        <h5>{t("form.password")}</h5>
        <input type="password" value={passwordRef.current} onChange={ev => {ev.preventDefault();setPasswordRef(ev.target.value)}}/>
      </div>
      <button onClick={ev => handleSubmit(ev)}>
        {t("form.login.button")}
      </button>
      <div className="formUnit small">
        <Link><h6>{t("form.login.newAccount")}</h6></Link>
        <Link><h6>{t("form.login.forgotPass")}</h6></Link>
      </div>
    </div>
  </div>
}
export default Login