import { useEffect, useRef, useState } from "react"
import config from "../config/api.config"
import Axios from "axios"
import { useParams } from "react-router-dom"
import Loading from "../components/Loading"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck, faChevronDown, faChevronLeft } from "@fortawesome/free-solid-svg-icons"
import { fri9g_notify } from "fri9g-notification-react"
const Product = (props) => {
  const {t,i18n,price,cartRef,setCartRef} = props
  const {id} = useParams()
  const [product,setProduct] = useState([])
  const productRef = useRef(product)
  const setProductRef = (data) => {
    productRef.current = data
    setProduct(data)
  }

  const [quantity,setQuantity] = useState(1)
  const quantityRef = useRef(quantity)
  const setQuantityRef = (data) => {
    quantityRef.current = data
    setQuantity(data)
  }

  const [images,setImages] = useState([])
  const imagesRef = useRef(images)
  const setImagesRef = (data) => {
    imagesRef.current = data
    setImages(data)
  }

  const [options,setOptions] = useState([])
  const optionsRef = useRef(options)
  const setOptionsRef = (data) => {
    optionsRef.current = data
    setOptions(data)
  }

  const [locale,setLocale] = useState(i18n.language)
  const localeRef = useRef(locale)
  const setLocaleRef = (data) => {
    localeRef.current = data
    setLocale(data)
  }

  const [localeActive,setLocaleActive] = useState(false)
  const localeActiveRef = useRef(localeActive)
  const setLocaleActiveRef = (data) => {
    localeActiveRef.current = data
    setLocaleActive(data)
  }

  const [loading,setLoading] = useState(true)
  const loadingRef = useRef(loading)
  const setLoadingRef = (data) => {
    loadingRef.current = data
    setLoading(data)
  }

  useEffect(()=>{
    Axios.get(`${config.api}p/product/${id}?locale=${localeRef.current}`).then(resp=>{
      setProductRef(resp.data)
      let op = []
      let order = resp.data.order_picture
      if(!Array.isArray(order)) order = [order]
      let pics = resp.data.pictures
      for (let i = 0; i < order.length; i++) {
        const element = order[i]
        for (let j = 0; j < pics.length; j++) {
          const ele = pics[j]
          if(ele.id === element) op.push(ele)
        }
      }
      setImagesRef(op)
      generateOptionRef()
      setLoadingRef(false)
    })

  },[id])

  useEffect(()=>{
    setLocaleRef(i18n.language)
  },[i18n.language])

  const generateOptionRef = () => {
    let a = productRef.current.option_type
    let o = {}
    for (let i = 0; i < a.length; i++) {
      const e = a[i]
      for (let j = 0; j < Object.keys(productRef.current.option).length; j++) {
        const el = productRef.current.option[Object.keys(productRef.current.option)[j]]
        if(!o[e] && el.type==="BOOLEAN") o[e] = false
        else if(!o[e] && el.option===e) o[e] = el.name 
      }
    }
    setOptionsRef(o)
  }

  const checkStock = (element=null) => {
    let stock = {...productRef.current.stock}
    let ks = Object.keys(stock)
    let a = productRef.current.option_type
    let i
    if(element !== null) i = a.indexOf(element.option)
    else i = a.length
    let key = []
    for (let j = 0; j < i; j++) {
      const e = a[j]
      for (let h = 0; h < Object.keys(productRef.current.option).length; h++) {
        const el = productRef.current.option[Object.keys(productRef.current.option)[h]]
        if(el.option === e && optionsRef.current[e] === el.name) key.push(el.id)
      }
    }
    for (let i = 0; i < key.length; i++) {
      const e = key[i]
      ks = ks.filter(el => (el.split("-")).includes(e))  
    }
    if(ks.length === 1 && element===null) return ks[0]
    for (let i = 0; i < ks.length; i++) {
      const e = ks[i]
      if((e.split("-")).includes(element.id) && stock[e]>0) return "stock"
    }
    return "noStock"
  }

  const addCart = (ev) => {
    ev.preventDefault()
    let stock = {...productRef.current.stock}
    let isStock = checkStock()
    if(stock[isStock]<quantityRef.current) {
      fri9g_notify.error(t('error.noStock'))
      return
    }
    let c = {...cartRef.current}
    c.products = c.products + quantity
    c.cart.push({id:productRef.current.id,option:isStock,quantity:quantityRef.current})
    setQuantityRef(1)
    setCartRef(c)
    fri9g_notify.success(t('cart.item.added'))
  }

  return <div className="product">
    {
      loadingRef.current ? <Loading/> : <div className="container">
      <div className="images">
        <img src={`${config.api}images/l/${imagesRef.current[0].file}`} alt={`pictures of ${productRef.current.name[i18n.language]}`} />
        {imagesRef.current.map((e,k)=>{
          if(k===0) return null
          return <div className="small" key={`images-${k}`}><img src={`${config.api}images/xs/${e.file}`} alt={`pictures of ${productRef.current.name[i18n.language]}`} /></div>
        })}
      </div>
      <div className="infos">
        <h2>{(productRef.current.name[locale]).normalize("NFD").replace(/[\u0300-\u036f]/g, "")}</h2>
        <h4>{price(productRef.current.price,productRef.current.currency)}</h4>
        <div className="options">
          {productRef.current.option_type.map((e,k) => <div className={`optionType ${productRef.current.option_details[e]['display-type']}`} key={`${e}-${k}`}>
            <h4>{t(`option.${e}`)}</h4>
            {Object.keys(productRef.current.option).map((ee,kk) => {
              let el = productRef.current.option[ee]
              if(el.option!==e) return null
              return <div className={`option ${el['display-type']} ${el["stock_dependant"] && checkStock(el)} ${optionsRef.current[e]===el.name && 'selected'}`} style={{background:el['display-type']==="COLOR" ? `${el["display-option"]}` : 'none'}} key={`${e}-${k}-${ee}-${kk}`} onClick={(ev)=>{ev.preventDefault();let o = {...optionsRef.current};if(el['display-type']!=="BOOLEAN") o[e]=el.name; else o[e] = !o[e];setOptionsRef(o)}}>
                {el.type==="BOOLEAN" && <><input className={`${optionsRef.current[el.option]&& "checked"}`} type="checkbox"/> <FontAwesomeIcon icon={faCheck}/> </>}
                {el.type!=="BOOLEAN" && <>{((t(`option.${el.name}`)).split(""))[0]}</>}
              </div>
            })}
          </div>)}
          <div className="quantityContainer">
            <h4>{t("product.quantity")}</h4>
            <div className="quantity">
              <button onClick={ev => {ev.preventDefault();if(quantityRef.current > 0) setQuantityRef(quantityRef.current - 1)}}>-</button>
              <div>{quantityRef.current}</div>
              <button onClick={ev => {ev.preventDefault();setQuantityRef(quantityRef.current + 1 )}}>+</button>
            </div>
          </div>
        </div>
        <button onClick={(ev)=>{addCart(ev)}}>
          {t("product.addCart")}
        </button>
        <p dangerouslySetInnerHTML={{__html:productRef.current.description[locale]}}></p>
        <div className={`selector ${localeActiveRef.current?"active":""}`}>
          <div className="title" onClick={(ev)=>{ev.preventDefault();setLocaleActiveRef(!(localeActiveRef.current))}}>
            <h4>{t(`locale.${localeRef.current}`)}</h4>
            {localeActiveRef.current?<FontAwesomeIcon icon={faChevronLeft}/>:<FontAwesomeIcon icon={faChevronDown}/>}
          </div>
          <div className="selection">
            {Object.keys(productRef.current.name).map((e,k) => {
              if(e===localeRef.current) return null
              return <h4 key={`locale-${k}-${e}`} onClick={()=>{setLocaleRef(e);setLocaleActiveRef(false)}}>{t(`locale.${e}`)}</h4>
            })}
          </div>
        </div>
      </div>
      </div>
    }
    
  </div>
}

export default Product