import { useEffect, useRef, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faXmark } from "@fortawesome/free-solid-svg-icons"
import Axios from 'axios'
import config from "../config/api.config"
import Loading from "../components/Loading"
import { faTrashCan } from "@fortawesome/free-regular-svg-icons"
import { fri9g_notify } from "fri9g-notification-react"
import authApi from "../services/authAPI"
import { useParams } from "react-router-dom"
import moment from "moment"

const Order = (props) => {
  const {t,i18n} = props
  const formatDate = (str) => moment(str).locale(i18n.language).format('L - LT')
  const {id} = useParams()

  const [order,setOrder] = useState({})
  const orderRef = useRef(order)
  const setOrderRef = (data) => {
    orderRef.current = data
    setOrder(data)
  }

  const [products,setProducts] = useState({})
  const productsRef = useRef(products)
  const setProductsRef = (data) => {
    productsRef.current = data
    setProducts(data)
  }

  const [loading,setLoading] = useState(true)
  const loadingRef = useRef(loading)
  const setLoadingRef = (data) => {
    loadingRef.current = data
    setLoading(data)
  }

  

  const checkLoad = () => {
    let p = {...productsRef.current}
    for (let i = 0; i < orderRef.current.cart.length; i++) {
      const e = orderRef.current.cart[i]
      if(!p[e.id]) return false
    }
    setLoadingRef(false)
  }

  const findPic = (e) => {
    let el = productsRef.current[e.id]
    let picId = el.order_picture[0]
    if(!Array.isArray(el.order_picture)) picId = [el.order_picture][0]
    for (let index = 0; index < el.pictures.length; index++) {
      const element = el.pictures[index]
      if(element.id === picId) return <img src={`${config.api}images/xs/${element.file}`} alt={e.name} />
    }
    return null
  }

  const findOption = (e) => {
    let el = productsRef.current[e.id]
    let opt = e.option.split('-')
    let r = ""
    if(opt.length===1 && opt[0]==="DEFAULT") return t(`option.DEFAULT`)
    for (let index = 0; index < opt.length; index++) {
      const element = opt[index]
      let oe = el.option[element]
      r += t(`option.${oe.name}`)
      if(index !== opt.length-1) r += " - "
    }
    return r
  }

  useEffect(()=>{
    setLoadingRef(true)
    Axios.get(`${config.api}order/${id}?locale=${i18n.language}`).then(resp => {
      setOrderRef(resp.data)
      for (let i = 0; i < resp.data.cart.length; i++) {
        const e = resp.data.cart[i]
        Axios.get(`${config.api}p/product/${e.id}?locale=${i18n.language}`).then(resp => {
          let p = {...productsRef.current}
          p[e.id] = resp.data
          setProductsRef(p)
          checkLoad()
        })
      }
      if(resp.data.products === 0) checkLoad()
      setLoadingRef(false)
    }).catch(err => {
      if(err?.response?.status === 403) fri9g_notify.error(t("auth.credentials.missing"))
      else fri9g_notify.error(t("general.internalError"))
    })
  },[id,i18n.language])

  const countProd = () => {
    let p = 0
    for (let i = 0; i < orderRef.current.cart.length; i++) {
      const element = orderRef.current.cart[i]
      p += element.quantity
    }
    return p
  }

  return <div className={`order`}>
    <div className="container">
      {loadingRef.current ?
        <Loading/>
        : <>
        <div className="cartHeader">
          <h1>{t("order.title",{id:orderRef.current.id})}  </h1>
          <h3>{t("cart.products",{nb:countProd()})}</h3>
        </div>
        <div className="cartBody">
            {orderRef.current.cart.map((e,k)=><div key={`cart-${e}-${k}`}>
              {productsRef.current[e.id] && <div className="productUnit">
                {console.log(productsRef.current[e.id])}
                <div className="pic">
                  {findPic(e)}
                </div>
                <div className="infos">
                  <h4 className="nom">{productsRef.current[e.id].name[i18n.language]}</h4>
                  <h4 className="price">{`${e.price} ${orderRef.current.currency.symbol}`}</h4>
                  <div className="subinfo">
                    <h4>{findOption(e)}</h4>
                    <h4>{t("cart.quantitySub",{nb:e.quantity})}</h4>
                  </div>
                </div>
              </div>}
            </div>)}
        </div>
        <div className="cartFooter">
          <h3>{t("cart.total")}</h3><h3>{`${orderRef.current.amount} ${orderRef.current.currency.symbol}`}</h3>
        </div>
      </>}
    </div>
  </div>
}
export default Order