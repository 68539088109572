import { useEffect, useRef, useState } from "react"

const Caroussel = (props) => {

  const {className,data,t} = props

  const [counter,setCounter] = useState(0)
  const counterRef = useRef(counter)
  const setCounterRef = (data) => {
    counterRef.current = data
    setCounter(data)
  }
  var intervalRef = useRef(null)
  const setIntervalRef = (data) => {
    intervalRef.current = data
  }

  useEffect(()=>{
    setIntervalRef(setInterval(()=>{
        setCounterRef((counterRef.current+1)%3)
      },10000))
    return () => {
      clearInterval(intervalRef.current)
      setIntervalRef(null)
    }
  },[])


  return <div className={`caroussel ${className}`}>
    <div className="filter"/>
    <div className="container" style={{transform:`translateX(-${counter*100/3}%)`}}>
      {data.map((e,k) => <div className="element" key={k}>
        <img src={e.src} alt={e.alt} />
        <h4><pre>{t(e.key)}</pre></h4>
      </div>)}
    </div>
  </div>
}

export default Caroussel