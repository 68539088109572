import Axios from "axios"
import { useEffect, useRef, useState } from "react"
import config from "../config/api.config"
import Loading from "./Loading"
import { fri9g_notify } from "fri9g-notification-react"
import authApi from "../services/authAPI"
import moment from "moment"

const AccountSupport = (props) => {
  const {t,i18n} = props
  const formatDate = (str) => moment(str).locale(i18n.language).format('L LT')

  const [supports,setSupports] = useState([])
  const supportsRef = useRef(supports)
  const setSupportsRef = (data) => {
    supportsRef.current = data
    setSupports(data)
  }

  const [loading,setLoading] = useState(true)
  const loadingRef = useRef(loading)
  const setLoadingRef = (data) => {
    loadingRef.current = data
    setLoading(data)
  }

  useEffect(()=>{
    Axios.get(`${config.api}user/${authApi.get("id")}/supports?locale=${i18n.language}`).then(resp => {
      setSupportsRef((resp.data["support_request"]).reverse())
      setLoadingRef(false)
    }).catch(err => {
      if(err?.response?.status === 403) fri9g_notify.error(t("auth.credentials.missing"))
      else fri9g_notify.error(t("general.internalError"))
    })
  },[i18n.language])

  return <div className="supports">{loadingRef.current ? <Loading /> : <>
      {supportsRef.current.map((e,k)=><div className="support" key={`support-${k}`}>
        <div className="date">{formatDate(e.updatedAt)}</div>
        <div className="count">{t("support.type."+e.type)}</div>
        <div className="amount">{e.subject}</div>
        <div className={`status ${e.status}`}>{t("support.status."+e.status)}</div>
      </div>)}
    </>}
  </div>

}
export default AccountSupport