import Axios from "axios"
import { useEffect, useRef, useState } from "react"
import config from "../config/api.config"
import Loading from "./Loading"
import { fri9g_notify } from "fri9g-notification-react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/free-solid-svg-icons"

const AccountInfo = (props) => {
  const {t} = props
  const [data,setData] = useState({})
  const dataRef = useRef(data)
  const setDataRef = (d) => {
    dataRef.current = d
    setData(d)
  }

  const [loading,setLoading] = useState(true)
  const loadingRef = useRef(loading)
  const setLoadingRef = (data) => {
    loadingRef.current = data
    setLoading(data)
  }

  const [username,setUsername] = useState("")
  const usernameRef = useRef(username)
  const setUsernameRef = (data) => {
    usernameRef.current = data
    setUsername(data)
  }

  const [email,setEmail] = useState("")
  const emailRef = useRef(email)
  const setEmailRef = (data) => {
    emailRef.current = data
    setEmail(data)
  }
  
  const [password,setPassword] = useState("")
  const passwordRef = useRef(password)
  const setPasswordRef = (data) => {
    passwordRef.current = data
    setPassword(data)
  }

  const [passwordConfirm,setPasswordConfirm] = useState("")
  const passwordConfirmRef = useRef(passwordConfirm)
  const setPasswordConfirmRef = (data) => {
    passwordConfirmRef.current = data
    setPasswordConfirm(data)
  }

  const [newsletter,setNewsletter] = useState(false)
  const newsletterRef = useRef(newsletter)
  const setNewsletterRef = (data) => {
    newsletterRef.current = data
    setNewsletter(data)
  }

  useEffect(()=>{
    Axios.get(`${config.api}auth/me`).then(resp => {
      setDataRef(resp.data)
      setUsernameRef(resp.data.username)
      setEmailRef(resp.data.email)
      setNewsletterRef(resp.data.newsletter)
      setLoadingRef(false)
    }).catch(err => {
      if(err?.response?.status === 403) fri9g_notify.error(t("auth.credentials.missing"))
      else fri9g_notify.error(t("general.internalError"))
    })
  },[])

  const handleSubmit = (ev) => {
    ev.preventDefault()
    if(passwordRef.current!==passwordConfirmRef.current) {
      fri9g_notify.error("form.login.passwordFailed")
      return
    }
    // setLoadingRef(true)
    if(newsletterRef.current !== dataRef.current.newsletter) {
      Axios.post(`${config.api}auth/newsletter`,{newsletter:newsletterRef.current})
    }
    if(emailRef.current !== dataRef.current.email) {
      Axios.post(`${config.api}auth/email`,{email:emailRef.current})
    }
    if(usernameRef.current !== dataRef.current.username) {
      Axios.post(`${config.api}auth/username`,{username:usernameRef.current})
    }

    Axios.post(`${config.api}auth/register`,{username:usernameRef.current,email:emailRef.current,password:passwordRef.current}).then(resp => {
      fri9g_notify.success("form.register.success")
    }).catch(err => {
      if(err.response?.data?.code) fri9g_notify.error(t(err.response.data.code))
      else fri9g_notify.error("form.register.failed")
    })

  }

  return <div className="info">
    {loadingRef.current ? 
    <Loading/>:<div className="form">
      <div className="container">
        <div className="formUnit">
          <h5>{t("form.username")}</h5>
          <input type="text" value={usernameRef.current} onChange={ev => {ev.preventDefault();setUsernameRef(ev.target.value)}}/>
        </div>
        <div className="formUnit">
          <h5>{t("form.email")}</h5>
          <input type="text" value={emailRef.current} onChange={ev => {ev.preventDefault();setEmailRef(ev.target.value)}}/>
        </div>
        <div className="formUnit">
          <h5>{t("form.password")}</h5>
          <input type="password" value={passwordRef.current} onChange={ev => {ev.preventDefault();setPasswordRef(ev.target.value)}}/>
          <h6>{t("form.passwordReq")}</h6>
        </div>
        <div className="formUnit">
          <h5>{t("form.password")}</h5>
          <input type="password" value={passwordConfirmRef.current} onChange={ev => {ev.preventDefault();setPasswordConfirmRef(ev.target.value)}}/>
        </div>
        <div className="formUnit BOOLEAN">
          <h3>{t("form.newsletter")}</h3>
          <div className="boolContainer"><input className={`${newsletterRef.current && "checked"}`} type="checkbox" value={newsletterRef.current} onChange={ev => {ev.preventDefault();setNewsletterRef(!newsletterRef.current)}}/><FontAwesomeIcon icon={faCheck}/></div>
        </div>
        <button onClick={ev => handleSubmit(ev)}>
          {t("form.modify")}
        </button>
      </div>
    </div>}
  </div>
}
export default AccountInfo