import Axios from "axios"
import { useEffect, useRef, useState } from "react"
import config from "../config/api.config"
import Loading from "./Loading"
import { fri9g_notify } from "fri9g-notification-react"
import authApi from "../services/authAPI"
import moment from "moment"
import { Link, useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons"

const SupportNav = (props) => {
  const {t,i18n,selectedRef,setSelectedRef,id} = props
  const formatDate = (str) => moment(str).locale(i18n.language).format('L - LT')
  const navigate = useNavigate()

  const [supports,setSupports] = useState([])
  const supportsRef = useRef(supports)
  const setSupportsRef = (data) => {
    supportsRef.current = data
    setSupports(data)
  }

  const [navActive,setNavActive] = useState(null)
  const navActiveRef = useRef(navActive)
  const setNavActiveRef = (data) => {
    navActiveRef.current = data
    setNavActive(data)
  }

  const [loading,setLoading] = useState(true)
  const loadingRef = useRef(loading)
  const setLoadingRef = (data) => {
    loadingRef.current = data
    setLoading(data)
  }

  useEffect(()=>{
    Axios.get(`${config.api}user/${authApi.get("id")}/supports?locale=${i18n.language}`).then(resp => {
      setSupportsRef((resp.data["support_request"]).reverse())
      setLoadingRef(false)
    }).catch(err => {
      if(err?.response?.status === 403) fri9g_notify.error(t("auth.credentials.missing"))
      else fri9g_notify.error(t("general.internalError"))
    })
  },[i18n.language,id])

  const handleButton = (ev) => {
    ev.preventDefault()
    setSelectedRef('new')
    navigate(t('url.supportRequestWithId',{id:'new'}))
  }

  return <div className={`supportNav ${navActiveRef.current && 'active'}`}>
    <FontAwesomeIcon className="burger" icon={faBars} onClick={ev=>{ev.preventDefault();setNavActiveRef(true)}}/>
    <FontAwesomeIcon className="close" icon={faXmark} onClick={()=>{setNavActiveRef(false)}}/>
    <div className={`container`}>
      {loadingRef.current ? <Loading/> : <nav>
        {supportsRef.current.map((e,k)=><Link to={t("url.supportRequestWithId",{id:e.id})} onClick={ev => {if(navActiveRef.current) setNavActiveRef(false)}} className={`link ${e.id===selectedRef.current && 'active'}`} key={`support-${k}`}>
          <h3>{e.subject}</h3>
          <h3>{t("support.status."+e.status)}</h3>
          <div className="small">
            <h6>{t("support.type."+e.type)}</h6>
            <h6>{formatDate(e.updatedAt)}</h6>
          </div>
        </Link>)}
      </nav>}
      <button onClick={ev=>handleButton(ev)}>{t("support.new")}</button>
    </div>
  </div>
}
export default SupportNav